import { createSliceForList } from "../helpers";

export const customisedMessageSlice = createSliceForList({
    name: "customisedMessage",
    reducers: {
      customisedMessageAdded: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
      },
      // messageFetched: (state, action) => {
      //   state.actionsLoading = false;
      //   state.dataForEdit = action.payload?.result;
      //   state.error = null;
      // },
      messageEdited: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
      },
      messageDeleted: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
      },
      messageListFetched: (state, action) => {
        // alert(JSON.stringify(action.payload));
        const { total, records } = action.payload?.result;
        state.listLoading = false; 
        state.error = null;
        state.records = records;
        state.total = total;
      },
      messageSent:(state, action) => {
        state.actionsLoading = false;
        state.error = null;
      },
    },
  });
  
  

  export const messageReceiverSlice = createSliceForList({
    name: "messageReceiver",
    reducers: {
      recdiverListFetched:(state, action) => {
        // alert('cms recdiverListFetched');
        // alert(JSON.stringify(action.payload));
        const { total, records } = action.payload?.result;
        state.listLoading = false; 
        state.error = null;
        state.records = records;
        state.total = total;
      },
    },
  });
  