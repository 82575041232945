import service from "../../../../../plugin/axios";

// export const GROUPS_URL = "/api/groups";
export const GROUPS_URL = "/business/group";

// export function findGroup(queryParams) {
// return service.get(`${GROUPS_URL}/find`, queryParams);
// }

export function findGroupList(queryParams) {
  return service.post(GROUPS_URL + "/getGroupList", queryParams);
}

export function findGroupDetail(id) {
  return service.get(`${GROUPS_URL}/groupDetail?eventId=${id}`);
}

export function findGroupDetailList(queryParams) {
  return service.post(GROUPS_URL + "/groupDetailList", queryParams);
}

export function updateStatus(id) {
  return service.post(`${GROUPS_URL}/updateStatus?groupId=${id}`);
}

export function changeStatus(queryParams) {
  return service.post(GROUPS_URL + "/changeStatus", queryParams);
}

export function getRoleList() {
  return service.get(`${GROUPS_URL}/getRoleList`);
}
