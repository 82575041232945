import { createSliceForList } from "../helpers";

export const partnerSlice = createSliceForList({
  name: "partner",
  reducers: {
    // getPromotorById
    partnersFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    partnerDetailFetched: (state, action) => {
      const { result } = action.payload;
      state.result = result;
      state.isLoading = false;
    },

    partnerSelectFetched: (state, action) => {
      const { records } = action.payload;
      state.partnerData = records;
    },
  },
});
