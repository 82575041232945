import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { loginByUsernameAndPassword } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  username: "",
  password: "",
};

// username: "admin",
// password: "admin",

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        loginByUsernameAndPassword(values.username, values.password)
          .then((data) => {
            const authToken = data["access_token"];
            const userId = data["user-id"];
            const userName = data["x-user-name"];
            const userStore = data["x-user-store"];
            // console.log(data);
            // console.log(data["user-id"]);
            // console.log(userId);
            // console.log(data);
            if (userStore) {
              localStorage.setItem("userStore", userStore);
            } else {
              localStorage.setItem("userStore", "");
            }

            disableLoading();
            props.login({ authToken, userId, userName, userStore });
          })
          .catch((error) => {
            setLoginErrorMessage(error);
            console.error(error);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <>
      <div className="login-signin">
        <div className="mb-20">
          <h3> Sign In To Admin </h3>
          <p className="opacity-60 font-weight-bold">
            {" "}
            Enter your details to login to your account:
          </p>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form"
          id="kt_login_signin_form"
        >
          {loginErrorMessage !== "" ? (
            <div className="form-group">
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{loginErrorMessage}</div>
              </div>
            </div>
          ) : null}

          <div className="form-group">
            <input
              {...formik.getFieldProps("username")}
              className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5"
              type="text"
              placeholder="Username"
              name="username"
              autoComplete="off"
            />
            <div className="fv-plugins-message-container">
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-help-block">{formik.errors.username}</div>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <input
              {...formik.getFieldProps("password")}
              className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5"
              type="password"
              placeholder="Password"
              name="password"
            />
            <div className="fv-plugins-message-container">
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-help-block">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>

          <div className="form-group d-flex flex-wrap justify-content-between align-items-center px-8">
            <div className="checkbox-inline">
              <label className="checkbox checkbox-outline checkbox-white text-white m-0">
                <input type="checkbox" name="remember" />
                <span> </span>Remember me
              </label>
            </div>
            {/* <a href="#" id="kt_login_forgot" className="text-white font-weight-bold">Forget Password ?</a> */}
          </div>

          <div className="form-group text-center mt-10">
            <button
              disabled={formik.isSubmitting}
              onClick={() => setLoginErrorMessage("")}
              type="submit"
              id="kt_login_signin_submit"
              className="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3"
            >
              Sign In
              {loading && <span className="ml-3 spinner spinner-white"> </span>}
            </button>
          </div>
        </form>
        {/* <div className="mt-10">
          <span className="opacity-70 mr-4">Don't have an account yet?</span>
          <a href="#" id="kt_login_signup" className="text-white font-weight-bold">Sign Up</a>
        </div> */}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
