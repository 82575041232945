import {createSliceForList} from '../helpers'

export const generalTicketsSlice = createSliceForList({
    name: 'generalTickets',
    reducers: {
        // fetchGeneralTickets
        generalTickesFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        generalTickeFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        statusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        generalTicketDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        generalTicketCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        generalTicketUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
    }
})
