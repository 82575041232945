import { createSliceForList } from "../helpers";

export const feedbackSlice = createSliceForList({
  name: "feedback",
  reducers: {
    feedbackListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    feedbackDetailFetched: (state, action) => {
      const result = action.payload;
      state.result = result;
      state.error = null;
      state.isLoading = false;
    },
  },
});
