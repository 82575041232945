import * as musicstyleApi from "./musicstylesApi";
import {musicstylesSlice} from "./musicstylesSlice"
import {callTypes} from "../helpers"

const {actions} = musicstylesSlice;

export const fetchMusicStyles = queryParams => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return musicstyleApi
        .findMusicStyles(queryParams)
        .then(result => {
            dispatch(actions.musicStylesFetched(result))
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};

export const fetchMusicStyle = musicStyleId => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    if (musicStyleId === undefined) {
        return dispatch(actions.musicStyleFetched(undefined))
    } else {
        return musicstyleApi
            .getMusicById(musicStyleId)
            .then(data => {
                dispatch(actions.musicStyleFetched(data))
            })
            .catch(error => {
                dispatch(actions.catchError({error, callType: callTypes.list}));
            })
    }
}

export const createMusicStyle = musicStylesForCreation => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return musicstyleApi
        .createMusicStyle(musicStylesForCreation)
        .then(({status, result}) => {
            dispatch(actions.musicStyleCreated({result}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const updateMusicStyle = musicStyle => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return musicstyleApi
        .updateMusicStyle(musicStyle)
        .then((result) => {
            dispatch(actions.musicStyleUpdated({musicStyle}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const deleteMusicStyles = ids => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return musicstyleApi
        .deleteMusicStyles(ids)
        .then(() => {
            dispatch(actions.musicStylesDeleted({ids}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const deleteMusicStyle = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return musicstyleApi
        .deleteMusicStyle(id)
        .then(({status, result}) => {
            dispatch(actions.musicStyleDeleted({id}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const updatemusicStyleStatus = (id) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return musicstyleApi
        .updateStatus(id)
        .then(() => {
            dispatch(actions.musicStyleStatusUpdated(id));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const getStyleSelect = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return musicstyleApi
        .getStyleSelect()
        .then(result => {
            dispatch(actions.styleSelect(result));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};
