import {createSliceForList} from '../helpers'

export const featuredDjsSlice = createSliceForList({
    name: 'featuredDjs',
    reducers: {
        // findFeaturedDjs
        featuredDjsFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        // featuredDjsAdd
        featuredDjsAdd: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteFeaturedDj
        featuredDjDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteFeaturedDjs
        featuredDjsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // getFeaturedDjById
        featuredDjFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        // updateFeaturedDjOrder
        featuredDjOrderUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        }

    }
})
