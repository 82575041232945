import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function({
  title = "Alert",
  message,
  show,
  setShow,
  closeBtnText = "Close",
  onClose,
}) {
  closeBtnText = closeBtnText || "Close";

  // const [showModal, setShowModal] = useState(show)

  const handleClose = () => {
    setShow(false);
    if (onClose) onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="justify-content-center" closeButton>
        <Modal.Title className="text-center"> {title} </Modal.Title>
      </Modal.Header>
      <Modal.Body> {message} </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          {closeBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
