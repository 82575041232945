import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";

const initialValues = {
  fullname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.fullname, values.username, values.password)
        .then(({ data: { authToken } }) => {
          props.register(authToken);
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <>
      <div className="login-signup">
        <div className="mb-20">
          <h3> Sign Up </h3>
          <p className="opacity-60">
            {" "}
            Enter your details to create your account{" "}
          </p>
        </div>
        <form className="form text-center" id="kt_login_signup_form">
          <div className="form-group">
            <input
              className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8"
              type="text"
              placeholder="Fullname"
              name="fullname"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8"
              type="text"
              placeholder="Email"
              name="email"
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8"
              type="password"
              placeholder="Password"
              name="password"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8"
              type="password"
              placeholder="Confirm Password"
              name="cpassword"
            />
          </div>
          <div className="form-group text-left px-8">
            <div className="checkbox-inline">
              <label className="checkbox checkbox-outline checkbox-white text-white m-0">
                <input type="checkbox" name="agree" />
                <span> </span>I Agree the
                <a href="#" className="text-white font-weight-bold ml-1">
                  {" "}
                  terms and conditions{" "}
                </a>
                .
              </label>
            </div>
            <div className="form-text text-muted text-center"> </div>
          </div>
          <div className="form-group">
            <button
              id="kt_login_signup_submit"
              className="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3 m-2"
            >
              {" "}
              Sign Up{" "}
            </button>
            <button
              id="kt_login_signup_cancel"
              className="btn btn-pill btn-outline-white font-weight-bold opacity-70 px-15 py-3 m-2"
            >
              {" "}
              Cancel{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
