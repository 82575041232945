import { createSlice } from "@reduxjs/toolkit";
import routes from "./routes";

function getRouteParams() {
  const params = localStorage.getItem("params");
  return params !== null ? JSON.parse(params) : null;
}

const initialState = {
  params: getRouteParams(),
  routes: routes,
};

export const routerSlice = createSlice({
  name: "router",
  initialState: initialState,
  reducers: {
    storeRouteParams: (state, action) => {
      let payload = action.payload;
      state.params = payload ? payload : null;
      localStorage.setItem("params", payload ? JSON.stringify(payload) : null);
    },
    clearRouteParams: (state, action) => {
      state.params = null;
      localStorage.removeItem("params");
    },
    // menuListFetched: (state, action) => {
    //   let { payload } = action;
    //   state.routes = payload;
    // },
  },
});
