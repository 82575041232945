import React, { useEffect, useRef, useState } from "react";
import "bootstrap";
import $ from "jquery";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.js";
import { KTUtil } from "../../../../../_metronic/_assets/js/components/util";
import moment from "moment";

const arrows = {
  leftArrow: '<i class="la la-angle-left"></i>',
  rightArrow: '<i class="la la-angle-right"></i>',
};

export default function({ date, onChange, id, name, strftime = "YYYYMMDD" }) {
  let [count, setCount] = useState(0);
  const ref = useRef();

  if (date) {
    var newDate = moment(date).format("DD/MM/YYYY");
  }

  name = name || "";
  id = name || "";

  useEffect(() => {
    if (count == 0 && ref.current) {
      $(ref.current)
        .datepicker({
          rtl: KTUtil.isRTL(),
          clearBtn: true,
          autoclose: true,
          todayHighlight: true,
          orientation: "bottom left",
          templates: arrows,
          format: "dd/mm/yyyy",
        })
        .on("changeDate", (e) => {
          if (e.date === undefined) {
            onChange(null);
          } else {
            onChange(moment(e.date).format(strftime));
          }
        });
      setCount(count + 1);
    }
  }, [ref.current]);

  return (
    <>
      <div
        style={{
          width: "140px",
        }}
      >
        <input
          ref={ref}
          type="text"
          className="form-control"
          name={name}
          readOnly
          placeholder={newDate || "Select date"}
          style={{
            height: "36px",
          }}
        />
      </div>
    </>
  );
}
