import { createSlice } from "@reduxjs/toolkit";

export const initialStateForList = {
  listLoading: false,
  actionsLoading: false,
  total: 0,
  records: null,
  error: null,
  dataForEdit: undefined,
  detail: {
    listLoading: false,
    result: false,
    total: 0,
    records: null,
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const createSliceForList = function({
  name,
  initialState = {},
  reducers = {},
}) {
  const slice = createSlice({
    name: name,
    initialState: {
      ...initialStateForList,
      ...(initialState ? initialState : {}),
    },
    reducers: {
      catchError: (state, action) => {
        state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      ...(reducers ? reducers : {}),
    },
  });
  return slice;
};
