import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { sizePerPageList } from "../../../modules/Hivee/_helpers/tableUIHelpers";
import CardHeaderTitleWithBack from "./card/CardHeaderTitleWithBack";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";

import { Pagination } from "../../../../_metronic/_partials/controls";

export default function({
  keyField,
  deleteTitle,
  title,
  showBack,
  backTo,
  columns,
  records,
  listLoading,
  total,
  onAdd,
  onDelete,
  uiContext,
  withPagination = true,
  withSelectRow = true,
  FilterRenderer,
  TotalRenderer,
  extralFilterData = {},
  ActionRenderer,
  defaultSorted = undefined,
  height,
  btnDeleteStyle = null,
  btnNewStyle = null,
  listSelectedId = null,
  rowKeyField=null,
}) {
  const uiContextProps = useMemo(() => {
    return {
      ids: uiContext.ids,
      setIds: uiContext.setIds,
      setQueryParams: uiContext.setQueryParams,
      queryParams: uiContext.queryParams,
    };
  }, [uiContext]);

  const paginationOptions = {
    custom: true,
    totalSize: total,
    sizePerPageList: sizePerPageList,
    sizePerPage: uiContextProps.queryParams.limit,
    page: uiContextProps.queryParams.page,
  };

  let selectRow = useMemo(() => {
    if (withSelectRow) {
      return getSelectRow(
        {
          records,
          ids: uiContextProps.ids,
          setIds: uiContextProps.setIds,
          listSelectedId:listSelectedId,
          rowKeyField:rowKeyField,
        },
        []
      );
    } else {
      return undefined;
    }
  }, [withSelectRow, uiContextProps, records]);

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderTitleWithBack
            title={title}
            showBack={showBack}
            backTo={backTo}
          />
          <CardHeaderToolbar>
            {(ActionRenderer && <ActionRenderer />) || (
              <>
                <Button className="mr-4" variant="danger" onClick={onAdd} style={btnNewStyle}>
                  Add New
                </Button>
                <Button variant="outline-danger" onClick={onDelete} style={btnDeleteStyle}>
                  {deleteTitle != null ? deleteTitle : "Delete"}
                </Button>
              </>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {FilterRenderer && <FilterRenderer {...extralFilterData} />}
          {(withPagination && (
            <PaginationProvider
              pagination={paginationFactory(paginationOptions)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      isLoading={listLoading}
                      paginationProps={paginationProps}
                    >
                      {TotalRenderer && <TotalRenderer />}
                      <div
                        style={{
                          height: height || "400px",
                          overflow: "auto",
                        }}
                      >
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          classes="table table-head-custom table-vertical-center overflow-hidden text-nowrap"
                          bootstrap4
                          bordered={false}
                          remote
                          keyField={keyField ?? "id"}
                          data={records === null ? [] : records}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          onTableChange={getHandlerTableChange(
                            uiContextProps.setQueryParams
                          )}
                          selectRow={selectRow}
                          {...paginationTableProps}
                        >
                          <PleaseWaitMessage records={records} />
                          <NoRecordsFoundMessage records={records} />
                        </BootstrapTable>
                      </div>
                    </Pagination>
                  </>
                );
              }}
            </PaginationProvider>
          )) || (
            <div
              style={{
                height: height || "400px",
                overflow: "auto",
              }}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={records === null ? [] : records}
                columns={columns}
                defaultSorted={defaultSorted || {}}
                onTableChange={getHandlerTableChange(
                  uiContextProps.setQueryParams
                )}
                selectRow={selectRow}
              >
                <PleaseWaitMessage records={records} />
                <NoRecordsFoundMessage records={records} />
              </BootstrapTable>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
