import { createSliceForList } from "../helpers";

export const staffSlice = createSliceForList({
  name: "staff",
  reducers: {
    staffListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    staffAssigned: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
