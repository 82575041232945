import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, select, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  DictListLoaded: "[Load DictList] Auth API",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  userId: undefined,
  userName: undefined,
  userStore: undefined,

  // 数据字典
  dictList: undefined,
  // 菜单
  menuList: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "hivee-admin-auth",
    whitelist: ["authToken", "userId", "userName", "userStore"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        let { authToken, userId, userName, userStore } = action.payload;
        return { authToken, user: undefined, userId, userName, userStore };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.DictListLoaded: {
        const { dictList } = action.payload;
        return { ...state, dictList };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: ({ authToken, userId, userName, userStore }) => ({
    type: actionTypes.Login,
    payload: { authToken, userId, userName, userStore },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillDictList: (dictList) => ({
    type: actionTypes.DictListLoaded,
    payload: { dictList },
  }),
  fulfillMenuList: (menuList) => ({}),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { userId } = yield select((state) => state.auth);
    const user = yield getUserByToken(userId);
    yield put(actions.fulfillUser(user));
  });
}
