import {createSliceForList} from '../helpers'

export const musicsSlice = createSliceForList({
    name: 'musics',
    reducers: {
        // findMusic
        musicsFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        // createMusic
        musicCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // getMusicById
        musicFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        // updateMusic
        musicUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteMusic
        musicDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteMusics
        musicsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // musicUpdateState
        musicStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        }
    }
})
