import * as menuApi from "./leftMenuApi";
import { leftMenuSlice } from "./leftMenuSlice";
import { callTypes } from "../helpers";

const { actions } = leftMenuSlice;

export const getLeftMenu = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return menuApi
    .getLeftMenu(queryParams)
    .then((result) => {
      dispatch(actions.leftMenuFetched(result));
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't find menu";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
