import { createSliceForList } from "../helpers";

export const roleSlice = createSliceForList({
  name: "role",
  reducers: {
    roleListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    roleAdded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    roleEdited: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    roleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    roleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    roleDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload;
      state.error = null;
    },
    allMenuFetched: (state, action) => {
      state.treeDataLoading = false;
      state.treeData = action.payload;
      state.error = null;
    },
    privilegesFetched: (state, action) => {
      const { result } = action.payload;
      state.privileges = result.menuIds;
      state.privilegesLoading = false;
      state.listLoading = false;
    },
    privilegesGiven: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
