import * as api from "./groupsApi";
import { groupsSlice } from "./groupsSlice";
import { callTypes } from "../helpers";

const { actions } = groupsSlice;

// export const findGroup = (queryParams) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.list }));
//   return api
//     .findGroup(queryParams)
//     .then((response) => {
//       const { total, records } = response.data;
//       dispatch(actions.groupFetched({ total, records }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't find group";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

export const findGroupList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return api
    .findGroupList(queryParams)
    .then((result) => {
      dispatch(actions.groupListFetched(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const findGroupDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return api
    .findGroupDetail(queryParams)
    .then((result) => {
      dispatch(actions.groupDetailFetched(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const findGroupDetailList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return api
    .findGroupDetailList(queryParams)
    .then((result) => {
      dispatch(actions.groupDetailListFetched(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateStatus = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return api
    .updateStatus(id)
    .then((result) => {
      dispatch(actions.groupStatusUpdated(id));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const changeStatus = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return api
    .changeStatus(queryParams)
    .then((result) => {
      dispatch(actions.memberStatusChanged(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getRoleList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return api
    .getRoleList()
    .then((result) => {
      dispatch(actions.roleListFetched(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
