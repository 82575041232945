import { createSliceForList } from "../helpers";

export const eventsSlice = createSliceForList({
  name: "events",
  reducers: {
    // getEventById
    eventFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload;
      state.error = null;
    },
    // findEvents
    eventsFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    // createEvent
    eventCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // duplicate event
    eventDuplicated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateEvent
    eventUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.dataForEdit = undefined;
    },
    // deleteEvent
    eventDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteEvents
    eventsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // eventsUpdateState
    eventsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
