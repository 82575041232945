import { createSliceForList } from "../helpers";

export const groupsSlice = createSliceForList({
  name: "groups",
  reducers: {
    // findGroup
    // groupFetched: (state, action) => {
    //   const { total, records } = action.payload;
    //   state.listLoading = false;
    //   state.error = null;
    //   state.records = records;
    //   state.total = total;
    // },
    groupListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    groupDetailFetched: (state, action) => {
      const { result } = action.payload.result;
      const { groupId } = result;
      state.isLoading = false;
      state.error = null;
      state.result = result;
      state.groupId = groupId;
    },
    groupDetailListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    groupStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    memberStatusChanged: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    roleListFetched: (state, action) => {
      const { result } = action.payload.result;
      state.roleData = result;
      state.roleLoading = false;
    },
  },
});
