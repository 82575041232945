export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  page: 1,
  limit: 10,
};
