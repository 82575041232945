import service from "../../../../../plugin/axios";

export const POST_URL = "/business/djs";

export function createDJ(data) {
  return service.post(POST_URL + "/addDJ", data);
}

export function getDJById(id) {
  return service.get(`${POST_URL}/getDJ/${id}`);
}

export function findDJs(queryParams) {
  return service.post(`${POST_URL}/djPage`, queryParams);
}

export function updateDJ(data) {
  return service.put(`${POST_URL}/updateDJ`, data);
}

export function deleteDJ(id) {
  return service.delete(`${POST_URL}/delDJ/${id}`);
}

export function deleteDJs(ids) {
  return service.delete(`${POST_URL}/delDJ/` + ids.join(","));
}

export function updateStatus(id) {
  return service.put(`${POST_URL}/updateStatus/${id}`);
}

export function getDjSelect() {
  return service.get(`${POST_URL}/getDjSelect`);
}
