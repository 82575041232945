import axios from "axios";
import request, { loginService } from "../../../../plugin/axios";

export const LOGIN_URL = "/auth/oauth/token";
export const DICT_LIST_URL = "/admin/dict/dictList";
export const UER_MENU_LIST_URL = "/admin/menuList";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "/admin/user/";

export function loginByUsernameAndPassword(
  username,
  password,
  randomStr = "",
  code = ""
) {
  const grantType = "password";
  const scope = "All";
  return loginService("/auth/oauth/token", {
    method: "POST",
    params: {
      username,
      password,
      randomStr,
      code,
      grant_type: grantType,
      scope,
    },
  });
}

export function register(email, fullname, username, password) {
  return request.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return request.post(REQUEST_PASSWORD_URL, { email });
}

// 查询用户信息
export function getUserByToken(userId) {
  // Authorization head should be fulfilled inemail interceptor.
  return request.get(ME_URL + userId);
}

// 查询数据字典
export function getDictList() {
  return request.get(DICT_LIST_URL);
}

// 查询用户菜单
export function getMenuList(userId) {
  return request.get(UER_MENU_LIST_URL);
}

// 获取初始化数据
export function getInitialData() {
  return axios.all([getDictList()]);
}
