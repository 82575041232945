import { createSliceForList } from "../helpers";

export const leftMenuSlice = createSliceForList({
  name: "leftMenu",
  reducers: {
    leftMenuFetched: (state, action) => {
      let { payload } = action;
      state.leftMenus = payload;
      state.menuLoading = false;
    },
  },
});
