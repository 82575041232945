import service from "../../../../../plugin/axios";

export const MUSICSTYLE_URL = "/business/MusicStyle";

export function findMusicStyles(queryParams) {
    return service.post(`${MUSICSTYLE_URL}/musicStylePage`, queryParams);
}

export function getMusicById(id) {
    return service.get(`${MUSICSTYLE_URL}/getMusicStyle/${id}`);
}

export function createMusicStyle(data) {
    return service.post(MUSICSTYLE_URL + '/addMusicStyle', data);
}

export function updateMusicStyle(data) {
    return service.put(`${MUSICSTYLE_URL}/updateMusicStyle`, data);
}

export function deleteMusicStyle(id) {
    return service.delete(`${MUSICSTYLE_URL}/delMusicStyle/${id}`);
}

export function deleteMusicStyles(ids) {
    return service.delete(`${MUSICSTYLE_URL}/delMusicStyle/` + ids.join(','));
}

export function updateStatus(id) {
    return service.put(`${MUSICSTYLE_URL}/updateStatus/${id}`)
}

export function getStyleSelect() {
    return service.get(`${MUSICSTYLE_URL}/getMusicStyleSelect`);
}

