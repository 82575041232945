import {createSliceForList} from '../helpers'

export const musicstylesSlice = createSliceForList({
    name: 'musicstyles',
    reducers: {
        // getMusicById
        musicStyleFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },

        // findMusicStyle
        musicStylesFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;

        },
        // createMusicStyle
        musicStyleCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // updateMusicStyle
        musicStyleUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteMusicStyle
        musicStyleDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteMusicStyles
        musicStylesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // musicStyleUpdateState
        musicStyleStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },

        styleSelect: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.records = action.payload;
        },

    }
})
