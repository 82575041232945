import {createSliceForList} from '../helpers'

export const featuredEventsSlice = createSliceForList({
    name: 'featuredEvents',
    reducers: {
        // findFeaturedEvents
        featuredEventsFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        // featuredEventsAdd
        featuredEventsAdd: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteFeaturedEvent
        featuredEventDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteFeaturedEvents
        featuredEventsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // getFeaturedEventById
        featuredEventFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        // updateFeaturedEventOrder
        featuredEventOrderUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        }

    }
})
