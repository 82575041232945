import service from "../../../../../plugin/axios";

export const URL = "/business/partner";

export function getPartnerList(queryParams) {
  return service.post(URL + "/getPartnerList", queryParams);
}

export function getPartnerById(id) {
  return service.get(`${URL}/getPartnerDetail/?partnerId=${id}`);
}

export function getPartnerSelect() {
  return service.get("/business/select/getPartner");
}
