/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const assembleUrl = (...to) => {
    return to.reduce((i, j) => {
      return i + j;
    });
  };
  // 一级菜单
  const oneLevelMenuData = [
    {
      to: "/event",
      text: "Event Management",
    },
    {
      to: "/post",
      text: "Post Management",
    },
    {
      to: "/dj",
      text: "DJ Management",
    },
    {
      to: "/featured",
      text: "Featured Management",
    },
    {
      to: "/advertisement",
      text: "Ad. Management",
    },
    {
      to: "/settings",
      text: "General Settings",
    },
    {
      to: "/order",
      text: "Order Management",
    },
  ];
  // 两级菜单
  const towLevelMenuData = [
    {
      to: "/admin",
      text: "Admin Management",
      children: [
        {
          to: "/order",
          text: "Order",
        },
      ],
    },
  ];

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {oneLevelMenuData.map((item) => {
          return (
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                item.to
              )}`}
            >
              <NavLink className="menu-link" to={item.to}>
                <span className="menu-text"> {item.text} </span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>
          );
        })}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        {towLevelMenuData.map((parent) => {
          let { children } = parent;
          if (!children) children = [];
          return (
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                parent.to
              )}`}
            >
              <NavLink className="menu-link menu-toggle" to="{parent.to}">
                <span className="menu-text"> {parent.text} </span>
                <i className="menu-arrow"> </i>
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  {children.map((child) => {
                    let url = assembleUrl(parent.to, child.to);
                    return (
                      <li className={`menu-item ${getMenuItemActive(url)}`}>
                        <NavLink className="menu-link" to="url">
                          <i className="menu-bullet menu-bullet-dot">
                            {" "}
                            <span />{" "}
                          </i>
                          <span className="menu-text"> {child.text} </span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>
          );
        })}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
