import MockAdapter from "axios-mock-adapter";
// my mocks
import mockTablePackages from "../../app/modules/Hivee/__mocks__/mockTablePackages"
//import mockPost from "../../app/modules/Hivee/__mocks__/mockPost"
import mockDJs from "../../app/modules/Hivee/__mocks__/mockDJs"
import mockMusics from "../../app/modules/Hivee/__mocks__/mockMusics"
import mockMusicStyles from "../../app/modules/Hivee/__mocks__/mockMusicStyles"
import mockPackage from "../../app/modules/Hivee/__mocks__/mockPackage"

export default function mockAxios(axios) {
    const mock = new MockAdapter(axios, {delayResponse: 300});
    // my mocks
    mockTablePackages(mock);
    //mockPost(mock);
    mockDJs(mock);
    mockMusics(mock);
    mockMusicStyles(mock);
    mockPackage(mock);
    return mock;
}
