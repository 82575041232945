import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
// hivee reducer
import { routerSlice } from "../app/modules/System/_redux/router/routerSlice";
import { eventsSlice } from "../app/modules/Hivee/_redux/events/eventsSlice";
import { generalTicketsSlice } from "../app/modules/Hivee/_redux/generalTickets/generalTicketsSlice";
import { vipTablesSlice } from "../app/modules/Hivee/_redux/vipTables/vipTablesSlice";
import { tablePackagesSlice } from "../app/modules/Hivee/_redux/tablePackages/tablePackagesSlice";
import { postSlice } from "../app/modules/Hivee/_redux/post/postSlice";
import { groupsSlice } from "../app/modules/Hivee/_redux/groups/groupsSlice";
import { djsSlice } from "../app/modules/Hivee/_redux/djs/djsSlice";
import { musicsSlice } from "../app/modules/Hivee/_redux/musics/musicsSlice";
import { musicstylesSlice } from "../app/modules/Hivee/_redux/musicstyles/musicstylesSlice";
import { adsSlice } from "../app/modules/Hivee/_redux/ads/adsSlice";
import { packageSlice } from "../app/modules/Hivee/_redux/packages/packageSlice";
import { popularSlice } from "../app/modules/Hivee/_redux/popular/popularSlice";
import { appUsersSlice } from "../app/modules/Hivee/_redux/appUsers/appUsersSlice";
import { staticPageSlice } from "../app/modules/Hivee/_redux/static/staticSlice";
import { featuredEventsSlice } from "../app/modules/Hivee/_redux/featuredEvents/featuredEventsSlice";
import { featuredPostsSlice } from "../app/modules/Hivee/_redux/featuredPosts/featuredPostsSlice";
import { featuredDjsSlice } from "../app/modules/Hivee/_redux/featuredDjs/featuredDjsSlice";
import { featuredMusicsSlice } from "../app/modules/Hivee/_redux/featuredMusics/featuredMusicsSlice";
import { ordersSlice } from "../app/modules/Hivee/_redux/orders/ordersSlice";
import { ticketSlice } from "../app/modules/Hivee/_redux/ticket/ticketSlice";
import { saleRecordSlice } from "../app/modules/Hivee/_redux/saleRecord/saleRecordSlice";
import { promotorSlice } from "../app/modules/Hivee/_redux/promotors/promotorSlice";
import { userDetailSlice } from "../app/modules/Hivee/_redux/userDetail/userDetailSlice";
import { staffSlice } from "../app/modules/Hivee/_redux/staff/staffSlice";
import { issueSlice } from "../app/modules/Hivee/_redux/issue/issueSlice";
import { feedbackSlice } from "../app/modules/Hivee/_redux/feedback/feedbackSlice";
import { roleSlice } from "../app/modules/Hivee/_redux/role/roleSlice";
import { versionSlice } from "../app/modules/Hivee/_redux/version/versionSlice";
import { systemUserSlice } from "../app/modules/Hivee/_redux/systemUser/systemUserSlice";
import { menuSlice } from "../app/modules/Hivee/_redux/menu/menuSlice";
import { leftMenuSlice } from "../app/modules/Hivee/_redux/leftMenu/leftMenuSlice";
import { partnerSlice } from "../app/modules/Hivee/_redux/partner/partnerSlice";
import { stripeSlice } from "../app/modules/Hivee/_redux/stripe/stripeSlice";
import { couponSlice } from "../app/modules/Hivee/_redux/coupon/couponSlice";
import { customisedMessageSlice,messageReceiverSlice } from "../app/modules/Hivee/_redux/customisedMessage/customisedMessageSlice";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  // hivee reducer
  router: routerSlice.reducer,
  events: eventsSlice.reducer,
  generalTickets: generalTicketsSlice.reducer,
  vipTables: vipTablesSlice.reducer,
  tablePackages: tablePackagesSlice.reducer,
  posts: postSlice.reducer,
  groups: groupsSlice.reducer,
  djs: djsSlice.reducer,
  musics: musicsSlice.reducer,
  musicstyles: musicstylesSlice.reducer,
  ads: adsSlice.reducer,
  packages: packageSlice.reducer,
  populars: popularSlice.reducer,
  appUsers: appUsersSlice.reducer,
  staticPages: staticPageSlice.reducer,
  featuredEvents: featuredEventsSlice.reducer,
  featuredPosts: featuredPostsSlice.reducer,
  featuredDjs: featuredDjsSlice.reducer,
  featuredMusics: featuredMusicsSlice.reducer,
  orders: ordersSlice.reducer,
  ticket: ticketSlice.reducer,
  saleRecord: saleRecordSlice.reducer,
  promotors: promotorSlice.reducer,
  userDetail: userDetailSlice.reducer,
  staff: staffSlice.reducer,
  issue: issueSlice.reducer,
  feedback: feedbackSlice.reducer,
  role: roleSlice.reducer,
  version: versionSlice.reducer,
  systemUser: systemUserSlice.reducer,
  menu: menuSlice.reducer,
  leftMenu: leftMenuSlice.reducer,
  partner: partnerSlice.reducer,
  stripe: stripeSlice.reducer,
  coupon: couponSlice.reducer,
  customisedMessage:customisedMessageSlice.reducer,
  messageReceiver:messageReceiverSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
