import React from "react";
import clsx from "clsx";
import { isFragment } from "react-is";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CardHeaderTitle } from "../../../../../_metronic/_partials/controls";

export default function({ title, backTo, showBack = false }) {
  const history = useHistory();

  const handleClickBackBtn = () => {
    if (backTo === undefined || backTo === null) {
      history.goBack();
    } else if (typeof backTo === "string" || isFragment(backTo)) {
      history.push(backTo);
    } else {
      backTo();
    }
  };

  return (
    <>
      <div className={clsx("card-title")}>
        {/* {icon} */}
        {/* Wrap string and fragments in CardHeaderTitle */
        typeof title === "string" || isFragment(title) ? (
          <CardHeaderTitle>
            {(showBack && (
              <>
                <a onClick={handleClickBackBtn}>
                  <SVG
                    style={{
                      marginLeft: "-7px",
                    }}
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Angle-left.svg"
                    )}
                    className="h-50 align-self-center"
                  >
                    {" "}
                  </SVG>
                </a>
                <span
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {title}
                </span>
              </>
            )) ||
              title}
          </CardHeaderTitle>
        ) : (
          title
        )}
      </div>
    </>
  );
}
