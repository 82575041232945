import React from "react";
import _ from "lodash";
import { Form, Row, Col } from "react-bootstrap";
import { useField } from "formik";

const getFieldCSSClasses = (touched, error) => {
  const classes = ["form-control"];
  if (touched && error) {
    classes.push("is-invalid");
  }

  if (touched && !error) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export default function({
  inputType,
  label,
  controlId,
  name,
  labelCol = 2,
  inputCol = 9,
  children,
  disabled = false,
  ...props
}) {
  if (controlId === undefined || controlId === null)
    controlId = _.camelCase(label); // camelCase
  if (name == undefined || name === null) name = controlId;

  const [field, meta, helpers] = useField({
    name,
  });

  let type = inputType || "text";

  return (
    <>
      <Form.Group as={Row} controlId={controlId}>
        {label ? (
          <Form.Label column lg={labelCol}>
            {" "}
            {label + ":"}{" "}
          </Form.Label>
        ) : (
          <></>
        )}
        <Col lg={inputCol}>
          {children ? (
            children
          ) : (
            <>
              <input
                className={getFieldCSSClasses(meta.touched, meta.error)}
                {...field}
                name={name}
                id={controlId}
                type={type}
                disabled={disabled}
                {...props}
              />
              {meta.error && meta.touched && (
                <div className="invalid-feedback"> {meta.error} </div>
              )}
            </>
          )}
        </Col>
      </Form.Group>
    </>
  );
}


export {getFieldCSSClasses}