import {createSliceForList} from '../helpers'

export const packageSlice = createSliceForList({
    name: 'packages',
    reducers: {
        // getPackageById
        packageFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        // findPackage
        packagesFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        // createPackage
        packageCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // updatePackage
        packageUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deletePackage
        packageDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deletePackages
        packagesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // packageUpdateState
        packageStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // packageDuplicate
        packageDuplicate: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        }
    }
})
