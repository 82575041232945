import { createSliceForList } from "../helpers";

export const staticPageSlice = createSliceForList({
  name: "staticPages",
  reducers: {
    // findAppUser
    staticPageFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    // appUserUpdateState
    staticPageUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
    staticDetailPageFetched: (state, action) => {
      const { result } = action.payload;
      state.result = result;
      state.error = null;
    },
    onboardingFetched: (state, action) => {
      const adImage = action.payload.adImage;
      const adLoadingTime = action.payload.adLoadingTime;
      state.adImage = adImage;
      state.adLoadingTime = adLoadingTime;
      state.error = null;
      state.infoLoading = false;
    },
    onboardingAdUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
  },
});
