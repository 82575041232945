import CustomDatePicker from './form/CustomDatePicker'
import {CustomDateRangePicker} from './form/CustomDateRangePicker'
import CustomFormInput from './form/CustomFormInput'
import CustomFormWrapper from './form/CustomFormWrapper'
import CustomImageInput from './form/CustomImageInput'
import CustomSelect from './form/CustomSelect'
import ConfirmDialog from './ConfirmDialog'
import CustomDropzone from './CustomDropzone'
import CustomEditor from './CustomEditor'
import TableCard from './TableCard'
import DeleteConfirmDialog from './DeleteConfirmDialog'
import DuplicateConfirmDialog from './DuplicateConfirmDialog'


export default {
    CustomDatePicker,
    CustomDateRangePicker,
    CustomFormInput,
    CustomFormWrapper,
    CustomImageInput,
    CustomSelect,
    ConfirmDialog,
    CustomDropzone,
    CustomEditor,
    TableCard,
    DeleteConfirmDialog,
    DuplicateConfirmDialog
}
