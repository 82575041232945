import { createSliceForList } from "../helpers";

export const djsSlice = createSliceForList({
  name: "djs",
  reducers: {
    // getDJById
    djFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload;
      state.error = null;
    },

    // findDJ
    djsFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
      console.log(records);
    },

    // createDJ
    djCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateDJ
    djUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteDJ
    djDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteDJs
    djsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    djStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    djSelect: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.records = action.payload;
    },
  },
});
