import { createSliceForList } from "../helpers";

export const versionSlice = createSliceForList({
  name: "versions",
  reducers: {
    versionInfoFetched: (state, action) => {
      state.result = action.payload;
      state.infoLoading = false;
    },
    versionUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
  },
});
