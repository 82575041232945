import {createSliceForList} from '../helpers'

export const postSlice = createSliceForList({
    name: 'posts',
    reducers: {
        // getPostById
        postFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },

        // findPost
        postsFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;

        },
        // createPost
        postCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // updatePost
        postUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deletePost
        postDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deletePosts
        postsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        postStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        }

    }
})
