import MockUtils from './mock.utils'

let allrows = [
    {
        "id": "g210508",
        "name": "g210508g210508g2105",
        "dataCreated": "11/06/2021",
        "author": "35",
        "image": null
    },
    {
        "id": "g210509",
        "name": "g210509",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null
    },
    {
        "id": "g2105091",
        "name": "g2105091",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null
    },
    {
        "id": "g2105092",
        "name": "g2105092",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g2105093",
        "name": "g2105093",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g2105094",
        "name": "g2105094",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g2105095",
        "name": "g2105095",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g2105096",
        "name": "g2105096",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g2105097",
        "name": "g2105097",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g2105098",
        "name": "g2105098",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g21050910",
        "name": "g21050910",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g21050911",
        "name": "g21050911",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    },
    {
        "id": "g21050912",
        "name": "g21050912",
        "dataCreated": "11/06/2021",
        "author": "40",
        "image": null

    }
]


export default function mockPackage(mock) {
    mock.onPost("api/package/find").reply((config) => {
        const mockUtils = new MockUtils();
        const queryParams = JSON.parse(config.data);
        const data = mockUtils.baseFilter(
            allrows,
            queryParams
        );
        console.log(data)
        return [200, data];
    });
}
