import React, { ReactElement, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import BraftEditor from "braft-editor";
import { ContentUtils } from "braft-utils";
import "braft-editor/dist/index.css";
import _draftJs from "draft-js";

const EditorWrapper = styled.div`
  .editor-wrapper {
    width: 100%;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

export default function TextEdit({ name }): ReactElement {
  // 设置编辑器初始内容
  const [dirty, setDirty] = useState(false);
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState("")
  );
  const { values, getFieldHelpers } = useFormikContext();

  const handleChange = (editorState) => {
    setEditorState(editorState);
    getFieldHelpers(name).setValue(editorState.toHTML());
  };

  useEffect(() => {
    if (dirty === false) {
      const _editorState = BraftEditor.createEditorState(values[name] || "");
      setEditorState(ContentUtils.insertHTML(_editorState));
    }
  }, [values]);

  return (
    <EditorWrapper>
      <div className="editor-wrapper">
        <BraftEditor
          language="en"
          onChange={handleChange}
          value={editorState}
          onFocus={() => {
            if (dirty === false) setDirty(true);
          }}
        />
      </div>
    </EditorWrapper>
  );
}
