import { useField } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "bootstrap-daterangepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";

const getInputGroupCSSClasses = (touched, error) => {
  const classes = ["input-group"];
  if (touched && error) {
    classes.push("is-invalid");
  }

  if (touched && !error) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const formatStr = "DD/MM/YYYY HH:mm";

const getFieldCSSClasses = (touched, error) => {
  const classes = ["form-control"];
  if (touched && error) {
    classes.push("is-invalid");
  }

  if (touched && !error) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function CustomDateRangePicker({ name, ...props }) {
  let [dateAndTime, setDateAndTime] = useState("");
  let [field, meta, helpers] = useField({ name });
  const ref = [];

  useEffect(() => {
    if (field.value.length > 0) {
      const startDate = moment(field.value[0] * 1000);
      const endDate = moment(field.value[1] * 1000);
      setDateAndTime(getDateTimeString(startDate, endDate));
      if (ref.length > 0) {
        ref[0].setStartDate(moment(field.value[0] * 1000));
        ref[0].setEndDate(moment(field.value[1] * 1000));
      }
    } else {
      ref[0].$picker.daterangepicker({ timePicker: true });
      // 重新绑定事件
      ref[0].$picker.on(
        "apply.daterangepicker",
        ref[0].makeEventHandler("onApply")
      );
      ref[0].$picker.on(
        "hide.daterangepicker",
        ref[0].makeEventHandler("onHide")
      );
      setDateAndTime("");
    }
  }, [field.value]);

  const getDateTimeString = (startDate, endDate) => {
    if (typeof startDate === "number") {
      startDate = moment(startDate);
    }
    if (typeof endDate === "number") {
      endDate = moment(endDate);
    }
    return startDate.format(formatStr) + " ~ " + endDate.format(formatStr);
  };

  return (
    <>
      <DateRangePicker
        ref={(el) => ref.splice(0, 1, el)}
        onApply={(event, picker) => {
          const { startDate, endDate } = picker;
          helpers.setValue([picker.startDate.unix(), picker.endDate.unix()]);
          setDateAndTime(startDate, endDate);
        }}
        onHide={(event, picker) => {
          field.onBlur({
            target: {
              type: "text",
              name: name,
              value: field.value,
            },
          });
        }}
        initialSettings={{
          timePicker: true,
        }}
      >
        <div className={getInputGroupCSSClasses(meta.touched, meta.error)}>
          <input
            type="text"
            className={getFieldCSSClasses(meta.touched, meta.error)}
            placeholder=""
            readOnly={true}
            value={dateAndTime}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="la la-calendar-check-o"> </i>
            </span>
          </div>
        </div>
      </DateRangePicker>
      {meta.error && meta.touched && (
        <div className="invalid-feedback"> {meta.error} </div>
      )}
    </>
  );
}
