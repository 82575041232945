import {createSliceForList} from '../helpers'

export const popularSlice = createSliceForList({
    name: 'populars',
    reducers: {

        popularFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },

        // update
        popularUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        }

    }
})
