import { createSlice } from "@reduxjs/toolkit";

export const userDetailSlice =  createSlice({
  name: 'userDetail',
  initialState: {
    userDetail: {
      loading: false,
      error: null,
      data: {}
    },
    groups: {
      list: [],
      loading: false,
      error: null,
      total: 0
    },
    orders: {
      list: [],
      loading: false,
      error: null,
      total: 0
    },
    tickets: {
      list: [],
      loading: false,
      error: null,
      total: 0
    },
    personal: {
      loading: false,
      data: {}
    },
    account: {
      loading: false,
      data: {}
    },
    wallet: {
      loading: false,
      data: {}
    }
  },
  reducers: {
    beginFetchLoading (state, { payload }) {
      const { name } = payload
      state[name].loading = true
    },
    fetchLoaded (state, { payload }) {
      const { name, ...options } = payload
      state[name] = {
        loading: false,
        ...options
      }
    },
  }
})