import * as djsApi from "./djsApi";
import {djsSlice} from "./djsSlice"
import {callTypes} from "../helpers"

const {actions} = djsSlice;

export const fetchDJs = queryParams => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return djsApi
        .findDJs(queryParams)
        .then(result => {
            dispatch(actions.djsFetched(result));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};

export const fetchDJ = djId => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    if (djId === undefined) {
        return dispatch(actions.djFetched(undefined))
    } else {
        return djsApi
            .getDJById(djId)
            .then(data => {
                dispatch(actions.djFetched(data))
            })
            .catch(error => {
                dispatch(actions.catchError({error, callType: callTypes.list}));
            })
    }
}

export const deleteDJ = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return djsApi
        .deleteDJ(id)
        .then(({status, result}) => {
            dispatch(actions.djDeleted({id}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const createDJ = djsForCreation => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return djsApi
        .createDJ(djsForCreation)
        .then(({status, result}) => {
            dispatch(actions.djCreated(result));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const updateDJ = dj => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return djsApi
        .updateDJ(dj)
        .then((result) => {
            dispatch(actions.djUpdated(dj));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const updateDJStatus = (id) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return djsApi
        .updateStatus(id)
        .then(() => {
            dispatch(actions.djStatusUpdated(id));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const deleteDJs = ids => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return djsApi
        .deleteDJs(ids)
        .then(() => {
            dispatch(actions.djsDeleted({ids}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.action}));
            throw error
        });
};

export const getDjSelect = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return djsApi
        .getDjSelect()
        .then(result => {
            dispatch(actions.djSelect(result));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};
