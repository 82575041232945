import { createSliceForList } from "../helpers";

export const promotorSlice = createSliceForList({
  name: "promotors",
  reducers: {
    // getPromotorById
    promotorFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload;
      state.error = null;
    },
    // findPromotor
    promotorsFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    // createPromotor
    promotorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    storePromotorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updatePromotor
    promotorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deletePromotor
    promotorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deletePromotors
    promotorsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // promotorUpdateState
    promotorStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // promotorDuplicate
    promotorDuplicate: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    promotorsEventFetched: (state, action) => {
      // console.log(action.payload);
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      // state.records = records;
      state.listEventDetail = records;
      state.total = total;
    },
    promotorsPostFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      // state.records = records;
      state.listPost = records;
      state.total = total;
    },

    promotorsInfoFetched: (state, action) => {
      const { result } = action.payload;
      const { avatar, name, email, phone, location, promoterCode } = result;
      // const { total, records } = action.payload;
      // state.listLoading = false;
      // state.error = null;
      // state.records = records;
      state.avatar = avatar;
      state.name = name;
      state.email = email;
      state.phone = phone;
      state.location = location;
      state.promoterCode = promoterCode;
    },

    usersFetched: (state, action) => {
      const { total, records } = action.payload;
      state.error = null;
      // state.records = records;
      state.listUser = records;
      state.total = total;
      state.listLoading = false;
    },

    ordersFetched: (state, action) => {
      const { total, records } = action.payload;
      state.error = null;
      state.records = records;
      state.total = total;
      state.listLoading = false;
    },
  },
});
