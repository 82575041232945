import {createSliceForList} from '../helpers'

export const adsSlice = createSliceForList({
    name: 'ads',
    reducers: {
        // getAdById
        adFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        // findAds
        adsFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        // createAd
        adCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // updateAd
        adUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteAd
        adDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteAds
        adsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // adsUpdateState
        adsStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            ;
        }
    }
})
