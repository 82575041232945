import { createSliceForList } from "../helpers";

export const systemUserSlice = createSliceForList({
  name: "systemUser",
  reducers: {
    systemUserListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    systemUserAdded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    systemUserEdited: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    systemUserDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    systemUserDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload;
      state.error = null;
      state.listLoading = false;
    },
    statusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    staffListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    staffAdded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
