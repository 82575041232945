import { createSliceForList } from "../helpers";

export const couponSlice = createSliceForList({
  name: "coupon",
  reducers: {
    couponListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    eventListFetched: (state, action) => {
      const { result } = action.payload;
      state.error = null;
      state.eventDate = result;
    },
    ticketListFetched: (state, action) => {
      state.error = null;
      state.ticketDate = action.payload?.result;
    },
    couponAdded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    couponFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload?.result;
      if(state.dataForEdit&&state.dataForEdit.applyAll===null){
        state.dataForEdit.applyAll=0;
      }
      state.error = null;
    },
    couponEdited: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    couponDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    couponStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    couponDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.result = action.payload?.result;
      state.error = null;
    },
    couponDetailListFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.records = action.payload?.records;
      state.total = action.payload?.total;
    },
  },
});
