import { createSliceForList } from "../helpers";

export const appUsersSlice = createSliceForList({
  name: "appUsers",
  reducers: {
    // findAppUser
    appUsersFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    // appUserUpdateState
    appUserStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    deleteAccountFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    // appUserUpdateState
    deleteAccountApprove: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // appUserUpdateState
    deleteAccountReject: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    customerFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
  },
});
