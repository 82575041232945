import MockUtils from './mock.utils'

let allrows = [
    {
        "id": "g210508",
        "musicStyleTitle": "g210508g210508g2105",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g210509",
        "musicStyleTitle": "g210509",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105091",
        "musicStyleTitle": "g2105091",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105092",
        "musicStyleTitle": "g2105092",
        "musicStyleStatus": ""
    },
    {
        "id": "g2105093",
        "musicStyleTitle": "g2105093",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105094",
        "musicStyleTitle": "g2105094",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105095",
        "musicStyleTitle": "g2105095",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105096",
        "musicStyleTitle": "g2105096",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105097",
        "musicStyleTitle": "g2105097",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g2105098",
        "musicStyleTitle": "g2105098",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g21050910",
        "musicStyleTitle": "g21050910",
        "musicStyleStatus": "Pop"
    },
    {
        "id": "g21050911",
        "musicStyleTitle": "g21050911",
        "musicStyleStatus": ""
    },
    {
        "id": "g21050912",
        "musicStyleTitle": "g21050912",
        "musicStyleStatus": ""
    }
]


export default function mockMusicStyles(mock) {
    mock.onPost("api/musicStyles/find").reply((config) => {
        const mockUtils = new MockUtils();
        const queryParams = JSON.parse(config.data);
        const data = mockUtils.baseFilter(
            allrows,
            queryParams
        );
        return [200, data];
    });
}
