import { createSliceForList } from "../helpers";

export const ticketSlice = createSliceForList({
  name: "ticket",
  reducers: {
    ticketFetched: (state, action) => {
      const { total, records } = action.payload;
      console.log(records);
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    ticketTotalFetched: (state, action) => {
      const { totalTicket, totalTable } = action.payload.result;
      state.listLoading = false;
      state.error = null;
      state.totalTicket = totalTicket;
      state.totalTable = totalTable;
    },
    freeTicketFetched: (state, action) => {
      const { total, records } = action.payload;
      console.log(records);
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    freeTicketTotalFetched: (state, action) => {
      const { totalTicket, totalTable } = action.payload.result;
      state.listLoading = false;
      state.error = null;
      state.totalTicket = totalTicket;
      state.totalTable = totalTable;
    },
    giftGeneralTicketFetched: (state, action) => {
      const { total, records } = action.payload;
      console.log(records);
      state.listLoading = false;
      state.error = null;
    },
    giftGeneralTicketCanceled: (state, action) => {
      // console.log("321");
    },
    storeGiftGeneralTicketFetched: (state, action) => {
      const { total, records } = action.payload;
      console.log(action.payload);
      state.listLoading = false;
      state.error = null;
    },
  },
});
