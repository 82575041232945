import React from 'react'
import {
    Form
} from 'react-bootstrap'
import styled from 'styled-components'

const FormWrapper = styled.div`
  @media (min-width: 992px) {
    .form-group.row label {
      text-align: right;
    }
  }
`;

export default FormWrapper
