import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function({
  title,
  textBody,
  show,
  closeBtnText,
  confirmBtnText,
  onClose,
  onConfirm,
}) {
  title = title || "Confirm";
  textBody = textBody || "Do you want to perform this action?";
  closeBtnText = closeBtnText || "Cancel";
  confirmBtnText = confirmBtnText || "Confirm";

  const handleClose = () => {
    if (onClose) onClose();
  };
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center"> {title} </Modal.Title>
      </Modal.Header>
      <Modal.Body> {textBody} </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={handleClose}>
          {closeBtnText}
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          {confirmBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
