import MockUtils from './mock.utils'

let allrows = [
    {
        "id": "t210508",
        "vipPackageId": "t210508",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210509",
        "vipPackageId": "t210509",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210510",
        "vipPackageId": "t210510",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210511",
        "vipPackageId": "t210511",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210513",
        "vipPackageId": "t210513",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210514",
        "vipPackageId": "t210514",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210515",
        "vipPackageId": "t210515",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210520",
        "vipPackageId": "t210520",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210516",
        "vipPackageId": "t210516",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210517",
        "vipPackageId": "t210508",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210518",
        "vipPackageId": "t210518",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    },
    {
        "id": "t210519",
        "vipPackageId": "t210519",
        "packageName": "Package 1",
        "package Name": "Maximum Magnum;King's Corner",
        "price": 20,
        "image": null
    }
]


export default function mockTablePackages(mock) {
    mock.onPost("api/tablePackages").reply((config) => {
        const mockUtils = new MockUtils();
        const queryParams = JSON.parse(config.data);
        const data = mockUtils.baseFilter(
            allrows,
            queryParams
        );
        return [200, data];
    });
}
