import React from "react";

function SelectionCheckbox({ isSelected, onChange }) {
  return (
    <>
      <input
        type="checkbox"
        style={{
          display: "none",
        }}
      />
      <label className="checkbox checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

function groupingItemOnSelect(props) {
  const { ids, setIds, customerId } = props;
  if (ids.some((id) => id === customerId)) {
    setIds(ids.filter((id) => id !== customerId));
  } else {
    const newIds = [...ids];
    newIds.push(customerId);
    setIds(newIds);
  }
}

function groupingAllOnSelect(props) {
  const { isSelected, setIds, records } = props;
  if (!isSelected) {
    const allIds = [];
    records.forEach((el) => allIds.push(el.id));
    setIds(allIds);
  } else {
    setIds([]);
  }

  return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
  const { records, ids, setIds,listSelectedId=null,rowKeyField=null } = props;
  return {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      let isSelected =
        records && records.length > 0 && records.length === ids.length;
      // alert('1 '+isSelected);
      if(listSelectedId!==null && rowKeyField !==null){
        if(records && records.length > 0){
          if(listSelectedId.length>0){
            const result = records.filter((n)=>{
              if(listSelectedId.indexOf(n[rowKeyField])!==-1) return n;
            })
            isSelected = records.length === result.length;
          }
          else{
            isSelected = false;
          }
        }
      }
      const props = { isSelected, records, setIds };
      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() => {
            if(listSelectedId!==null && rowKeyField !==null){
              const listFilterId =  records.map(n=>n[rowKeyField]);
              if(isSelected){//取消勾选
                // listSelectedId.forEach(function(item, index, arr) {
                //   if(listFilterId.indexOf(item)  !== -1) {
                //       arr.splice(index, 1);
                //   }
                // });
                listFilterId.forEach(function(item, index, arr) {
                  const numIndex = listSelectedId.indexOf(item);
                  if(numIndex !==-1){
                    listSelectedId.splice(numIndex, 1);
                  }
                });
              }
              else{//勾选
                listFilterId.forEach((item)=>{
                  if(listSelectedId.indexOf(item)  === -1){
                    listSelectedId.push(item);
                  }
                });
              }
            }
            groupingAllOnSelect(props);
          }}
        />
      );
    },
    selectionRenderer: ({ rowIndex }) => {
      let isSelected = ids.some((el) => el === records[rowIndex].id);
      if(listSelectedId!==null && rowKeyField !==null){
        if(listSelectedId.indexOf(records[rowIndex][rowKeyField])!==-1){
          isSelected = true;
        }
        else{
          isSelected = false;
        }
        if(listSelectedId.length===0) isSelected = false;
      }
      const props = { ids, setIds, customerId: records[rowIndex].id };
      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() => {
            groupingItemOnSelect(props)
            if(listSelectedId!==null && rowKeyField !==null){
              const keyId = records[rowIndex][rowKeyField]
              if(isSelected){
                listSelectedId.forEach(function(item, index, arr) {
                  if(item == keyId) {
                      arr.splice(index, 1);
                  }
                });
                // alert(JSON.stringify(listSelectedId));
              }
              else{
                listSelectedId.push(keyId);
                // alert(JSON.stringify(listSelectedId));
              }
            }
          }}
        />
      );
    },
  };
}
