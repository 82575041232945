import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { validatenull } from "../../libs/validate";
import { actions } from "../../app/modules/Auth/_redux/authRedux";
import { ReactReduxContext } from "react-redux";
import CustomConfirm from "../../app/modules/Hivee/components/CustomConfirm";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // 跨域请求，允许保存cookie
  timeout: 30000, // 请求超时时间
};

// 创建 axios 实例
const service = axios.create(config);
// 于用户密码登录
const loginService = axios.create(config);

export function ServiceProvider({ children }) {
  const { store } = useContext(ReactReduxContext);
  const [errorMessage, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  loginService.interceptors.request.use((config) => {
    config.headers["Authorization"] =
      "Basic NWQyMmViNmU4YjBjN2JhMDY2MDE0Mzk4OjEyMzQ1Ng==";
    return config;
  });
  loginService.interceptors.response.use(
    ({ data }) => data,
    (error) => {
      let promise = new Promise((resolve, reject) => {
        switch (error.response.status) {
          case 401:
            reject("The username or password is incorrect");
            break;
          default:
            reject("Login failed");
        }
      });
      return promise;
    }
  );

  // HTTPrequest拦截
  // 如果redux中有authToken，则将其放入请求首部
  service.interceptors.request.use(
    (config) => {
      const { authToken, userName, userStore } = store.getState().auth;
      if (authToken) {
        // token 验证
        config.headers["Authorization"] = "Bearer " + authToken;
        config.headers["uuid"] = userName;
      }
      if (userStore) {
        config.headers["x-user-store"] = userStore;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // HTTPresponse拦截
  useMemo(() => {
    service.interceptors.response.use(
      (response) => {
        if (process.env.NODE_ENV !== "production") {
          console.log(response);
        }
        // if (response) {
        let data = response.data;
        if (data) {
          const { result } = data;
          if (result === undefined) return data;
          let res = data;
          if (res.status && res.status !== "SUCCEED") {
            let message = res.message;
            if (!validatenull(message)) {
              setMessage(message);
              setShowAlert(true);
            }
            if (res.status === "ERROR") {
              return new Promise((resolve, reject) => reject(message));
            }
          }
          return res;
        }
        // }
      },
      (error) => {
        let errMsg;
        if (error && error.response) {
          if (
            error.response.data &&
            error.response.data.status &&
            error.response.data.status === "FAILED"
          ) {
            errMsg = error.response.data.message;
          }
          if (validatenull(errMsg)) {
            switch (error.response.status) {
              case 401:
                // 用户未授权
                store.dispatch(actions.logout());
                break;
            }
          }
          if (validatenull(errMsg)) {
            switch (error.response.status) {
              case 400:
                errMsg = "错误请求, 请检查请求参数";
                if (error.response.data.message) {
                  errMsg = error.response.data.message;
                }
                break;
              case 401:
                errMsg = "当前操作没有权限";
                if (
                  error.response.data &&
                  error.response.data.error === "invalid_token"
                ) {
                  errMsg = "无效的Token";
                  store.dispatch(actions.logout());
                }
                break;
              case 403:
                errMsg = "当前操作没有权限";
                break;
              case 404:
                errMsg = "未找到该资源";
                break;
              case 405:
                errMsg = "请求方法未允许";
                break;
              case 408:
                errMsg = "请求超时";
                break;
              case 478:
                errMsg = "验证码错误,请重新输入";
                break;
              case 479:
                errMsg = "演示环境，没有权限操作";
                break;
              case 500:
                errMsg = "服务器端出错";
                break;
              case 501:
                errMsg = "网络未实现";
                break;
              case 502:
                errMsg = "网络错误";
                break;
              case 503:
                errMsg = "服务不可用";
                break;
              case 504:
                errMsg = "网络超时";
                break;
              case 505:
                errMsg = "http版本不支持该请求";
                break;
              default:
                errMsg = "系统未知错误,请反馈给管理员";
                break;
            }
          }
        }

        if (!validatenull(errMsg)) {
          error.message = errMsg;
          //   // Message({
          //   //   message: errMsg,
          //   //   type: 'error'
          //   // })
        }
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <>
      <>
        <CustomConfirm
          // show={true}
          message={errorMessage}
          show={showAlert}
          setShow={setShowAlert}
        />
        {children}
      </>
    </>
  );
}

export default service;

export { loginService };
