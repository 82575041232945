import {createSliceForList} from '../helpers'

export const featuredPostsSlice = createSliceForList({
    name: 'featuredPosts',
    reducers: {
        // findFeaturedPosts
        featuredPostsFetched: (state, action) => {
            const {total, records} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.records = records;
            state.total = total;
        },
        // featuredPostsAdd
        featuredPostsAdd: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteFeaturedPost
        featuredPostDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // deleteFeaturedPosts
        featuredPostsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // getFeaturedPostById
        featuredPostFetched: (state, action) => {
            state.actionsLoading = false;
            state.dataForEdit = action.payload;
            state.error = null;
        },
        // updateFeaturedPostOrder
        featuredPostOrderUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        }

    }
})
