import MockUtils from './mock.utils'

let allrows = [
    {
        "id": "g210508",
        "djName": "g210508g210508g210508g2100000",
        "avatar": "",
        "noOfMusic": "35"

    },
    {
        "id": "g210509",
        "djName": "g210509",
        "avatar": "",
        "noOfMusic": "40"

    },
    {
        "id": "g2105091",
        "djName": "g2105091",
        "avatar": "",
        "noOfMusic": "40"

    },
    {
        "id": "g2105092",
        "djName": "g2105092",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g2105093",
        "djName": "g2105093",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g2105094",
        "djName": "g2105094",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g2105095",
        "djName": "g2105095",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g2105096",
        "djName": "g2105096",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g2105097",
        "djName": "g2105097",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g2105098",
        "djName": "g2105098",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g21050910",
        "djName": "g21050910",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g21050911",
        "djName": "g21050911",
        "avatar": "",
        "noOfMusic": "40"


    },
    {
        "id": "g21050912",
        "djName": "g21050912",
        "avatar": "",
        "noOfMusic": "40"


    }
]


export default function mockDJs(mock) {
    mock.onPost("api/djs/find").reply((config) => {
        const mockUtils = new MockUtils();
        const queryParams = JSON.parse(config.data);
        const data = mockUtils.baseFilter(
            allrows,
            queryParams
        );
        return [200, data];
    });
}
