import { createSliceForList } from "../helpers";

export const menuSlice = createSliceForList({
  name: "menu",
  reducers: {
    menuListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    menuDetailFetched: (state, action) => {
      state.dataForEdit = action.payload;
      state.error = null;
      state.actionsLoading = false;
    },
    menuEdited: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    menuDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    leftMenuFetched: (state, action) => {
      let { payload } = action;
      state.leftMenus = payload;
      state.menuLoading = false;
      // const { records } = action.payload;
      // state.leftListLoading = false;
      // state.error = null;
      // state.routes = records;
    },
  },
});
