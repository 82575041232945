import React, { Suspense, lazy, Component, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { actions } from "./modules/Auth/_redux/authRedux";
import * as leftMenuActions from "./modules/Hivee/_redux/leftMenu/leftMenuActions";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";

// import * as routerActions from "./modules/System/_redux/router/routerActions";
import * as groupAction from "./modules/Hivee/_redux/groups/groupsActions";
import * as djActions from "./modules/Hivee/_redux/djs/djsActions";
import * as musicStyleActions from "./modules/Hivee/_redux/musicstyles/musicstylesActions";
import * as partnerActions from "./modules/Hivee/_redux/partner/partnerActions";

import "./modules/Hivee/components";
import { flattenRoutes } from "./modules/Hivee/_helpers/routerHelper";
import { getInitialData } from "./modules/Auth/_redux/authCrud";

export default function BasePage() {
  const { routes } = useSelector((state) => state.router, shallowEqual);
  const { leftMenus, menuLoading } = useSelector(
    (state) => state.leftMenu,
    shallowEqual
  );

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [homepage, setHomepage] = useState("/events");
  // console.log(testroutes);
  useEffect(() => {
    // 加载初始化数据
    dispatch(groupAction.getRoleList());
    dispatch(musicStyleActions.getStyleSelect());
    dispatch(djActions.getDjSelect());
    dispatch(partnerActions.getPartnerSelect());
    dispatch(leftMenuActions.getLeftMenu());
    getInitialData()
      .then(([dictList]) => {
        // dispatch(routerActions.getUserMenuList());
        dispatch(actions.fulfillDictList(dictList));
      })
      .catch((error) => {
        // 初始化错误
        dispatch(actions.logout());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    if (leftMenus?.length > 0) {
      var new_homepage = leftMenus[0].path;
      if (leftMenus[0].children && !leftMenus[0].children[0]?.hide) {
        new_homepage = new_homepage + leftMenus[0].children[0].path;
      }
      setHomepage(new_homepage);
    }
  }, [leftMenus]);

  // console.log(homepage);

  return (
    <>
      {/* {isLoading ? ( */}
      {isLoading || menuLoading == undefined || menuLoading ? (
        <LayoutSplashScreen />
      ) : (
        <Suspense fallback={<LayoutSplashScreen />}>
          <CacheSwitch>
            {
              /* Redirect from root URL to first route. */
              <Redirect exact from="/" to={homepage} />
            }
            {/* 循环路由 */}
            {/* {flattenRoutes(routes).map((route, index) => { */}/
            {flattenRoutes(leftMenus).map((route, index) => {
              if (
                route.name == "generalTicketList" ||
                route.name == "vipTableList"
                // || route.name == "promotorDetail"
              ) {
                return (
                  <CacheRoute
                    key={`route-${index}`}
                    path={route.path}
                    exact
                    component={lazy(() =>
                      import(`./pages/${route.componentPath}`)
                    )}
                  />
                );
              }
              if (route.hide) {
                return (
                  <ContentRoute
                    key={`route-${index}`}
                    path={route.path}
                    exact
                    component={lazy(() =>
                      import(`./pages/${route.componentPath}`)
                    )}
                  />
                );
              }
              return (
                <CacheRoute
                  key={`route-${index}`}
                  path={route.path}
                  exact
                  component={lazy(() =>
                    import(`./pages/${route.componentPath}`)
                  )}
                />
              );
            })}
            <Redirect to="/error/error-v1" />
          </CacheSwitch>
        </Suspense>
      )}
    </>
  );
}
