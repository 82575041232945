/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { assemblePath } from "../../../../../app/modules/Hivee/_helpers/routerHelper";

import * as leftMenuActions from "../../../../../app/modules/Hivee/_redux/leftMenu/leftMenuActions";

const ParentMenu = ({ route, children, getMenuItemActive }) => {
  const showSubMenu =
    route.children &&
    route.children.filter((i) => i.hide === undefined || i.hide !== true)
      .length > 0;
  return (
    <li
      key={route.path}
      className={`menu-item ${getMenuItemActive(route.path, true)}`}
      aria-haspopup="true"
      {...(showSubMenu ? { "data-menu-toggle": true } : {})}
    >
      <NavLink
        className={`menu-link ${showSubMenu ? "menu-toggle" : ""}`}
        to={route.path}
      >
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl(route.icon)} />
        </span>
        <span className="menu-text"> {route.text} </span>
        {showSubMenu && <i className="menu-arrow" />}
      </NavLink>
      {showSubMenu && children}
    </li>
  );
};

const ChildMenuList = ({ parent, childrenMenu, getMenuItemActive }) => {
  return (
    <>
      <div className="menu-submenu ">
        <ul className="menu-subnav">
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text"> {parent.text} </span>
              </span>
            </li>
            {/*begin::2 Level*/}
            {childrenMenu.map((child, childIndex) => {
              let path = assemblePath(parent.path, child.path);
              return (
                <li
                  key={path}
                  className={`menu-item ${getMenuItemActive(path)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={path}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> {child.text} </span>
                  </NavLink>
                </li>
              );
            })}
            {/*end::2 Level*/}
          </ul>
        </ul>
      </div>
    </>
  );
};

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();
  // console.log(testroutes);
  useEffect(() => {
    // 加载初始化数据
    dispatch(leftMenuActions.getLeftMenu());
  }, []);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const { routes } = useSelector((state) => state.router, shallowEqual);
  const { leftMenus, menuLoading } = useSelector(
    (state) => state.leftMenu,
    shallowEqual
  );
  // 一级菜单
  const oneLevelMenuData = [
    // {
    //     "to": "/orders",
    //     "icon": "/media/svg/icons/General/Clipboard.svg",
    //     "text": "Order Management"
    // },
    // {
    //   to: "/Promotors",
    //   icon: "/media/svg/icons/General/Smile.svg",
    //   text: "Promotor Management",
    // },
  ];
  // 两级菜单
  const towLevelMenuData = [
    // {
    //   to: "/admin",
    //   text: "Admin Management",
    //   icon: "/media/svg/icons/Files/User-folder.svg",
    //   children: [
    //     {
    //       to: "/roles",
    //       text: "Role",
    //       icon: "",
    //     },
    //   ],
    // },
  ];

  return (
    <>
      {menuLoading == undefined || menuLoading ? (
        <></>
      ) : (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* {routes */}
          {leftMenus
            .filter((i) => i.hide === undefined || i.hide !== true)
            .map((parent) => {
              let childrenMenu = (parent.children || []).filter(
                (i) => i.hide === undefined || i.hide !== true
              );
              return (
                <ParentMenu
                  key={parent.path}
                  route={parent}
                  getMenuItemActive={getMenuItemActive}
                >
                  <ChildMenuList
                    parent={parent}
                    childrenMenu={childrenMenu}
                    getMenuItemActive={getMenuItemActive}
                  />
                </ParentMenu>
              );
            })}

          {oneLevelMenuData.map((item) => {
            return (
              <li
                key={item.to}
                className={`menu-item ${getMenuItemActive(item.to, false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={item.to}>
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl(item.icon)} />
                  </span>
                  <span className="menu-text"> {item.text} </span>
                </NavLink>
              </li>
            );
          })}
          {towLevelMenuData.map((parent, parentIndex) => {
            let { children } = parent;
            if (!children) children = [];
            return (
              <li
                key={parent.to}
                className={`menu-item ${getMenuItemActive(parent.to, true)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to={parent.to}>
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl(parent.icon)} />
                  </span>
                  <span className="menu-text"> {parent.text} </span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <ul className="menu-subnav">
                    <ul className="menu-subnav">
                      <li
                        className="menu-item menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text"> {parent.text} </span>
                        </span>
                      </li>
                      {/*begin::2 Level*/}
                      {children.map((child, childIndex) => {
                        let url = assemblePath(parent.to, child.to);
                        return (
                          <li
                            key={`${parent.to + child.to}`}
                            className={`menu-item ${getMenuItemActive(url)}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to={url}>
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text"> {child.text} </span>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
