import MockUtils from './mock.utils'

let allrows = [
    {
        "id": "g210508",
        "musicTitle": "g210508g210508g2105",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g210509",
        "musicTitle": "g210509",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105091",
        "musicTitle": "g2105091",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105092",
        "musicTitle": "g2105092",
        "musicStyle": "",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105093",
        "musicTitle": "g2105093",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105094",
        "musicTitle": "g2105094",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105095",
        "musicTitle": "g2105095",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105096",
        "musicTitle": "g2105096",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105097",
        "musicTitle": "g2105097",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g2105098",
        "musicTitle": "g2105098",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g21050910",
        "musicTitle": "g21050910",
        "musicStyle": "Pop",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g21050911",
        "musicTitle": "g21050911",
        "musicStyle": "",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    },
    {
        "id": "g21050912",
        "musicTitle": "g21050912",
        "musicStyle": "",
        "uploadTime": "11/11/2020 11:20 a.m.",
        "djName": "Emily",
        "musicStatus": "Suspend"
    }
]


export default function mockMusics(mock) {
    mock.onPost("api/musics/find").reply((config) => {
        const mockUtils = new MockUtils();
        const queryParams = JSON.parse(config.data);
        const data = mockUtils.baseFilter(
            allrows,
            queryParams
        );
        return [200, data];
    });
}
