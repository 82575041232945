import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";
import { useField } from "formik";

export default function({
  controlId,
  placeholder,
  hideSearch = true,
  multiple = false,
  initOptions = {},
  data = [],
  onSelect,
  name,
  defaultValue,
  disabled,
  selectStyle=null,
}) {
  const ref = useRef();
  let [field, meta, helpers] = useField({ name });
  let [count, setCount] = useState(0);
  name = name || "";

  useEffect(() => {
    if (count < 2 && ref.current) {
      $(ref.current).select2({
        ...initOptions,
        ...(hideSearch ? { minimumResultsForSearch: -1 } : {}),
        multiple,
        data,
      });
      if (count == 0) {
        $(ref.current).on("select2:select", (e) => {
          let { text, id } = e.params.data;
          onSelect({ id, text });
        });
      }
      setCount(count + 1);
    }
  }, [ref.current]);

  useEffect(() => {
    let id = field.value;
    if (id == "" || id == -1) {
      id = data[0].id;
    }
    $(ref.current)
      .val(id)
      .trigger("change");
  }, [field.value]);

  return (
    <>
      <select
        defaultValue={defaultValue}
        ref={ref}
        className="form-control select2"
        name={name}
        id={controlId}
        disabled={disabled}
        style={{
          opacity:1,
          ...selectStyle
        }}
      ></select>
    </>
  );
}
