import { createSliceForList } from "../helpers";

export const ordersSlice = createSliceForList({
  name: "orders",
  reducers: {
    // findOrder
    orderFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },

    orderDetailFetched: (state, action) => {
      const { total, result } = action.payload;
      const { records } = result;
      state.detail.listLoading = false;
      state.detail.error = null;
      state.detail.result = result;
      state.detail.records = records;
      state.detail.total = total;

      const {
        orderNo,
        purchaser,
        email,
        phone,
        lineItems,
        purchasedTime,
        status,
        promoterEmail,
        totalPrice,
        gst,
        bookingFee,
        promoterCode,
        couponCode,
      } = result;
      state.detail.orderNo = orderNo;
      state.detail.purchaser = purchaser;
      state.detail.email = email;
      state.detail.phone = phone;
      state.detail.lineItems = lineItems;
      state.detail.purchasedTime = purchasedTime;
      state.detail.status = status;
      state.detail.promoterEmail = promoterEmail;
      state.detail.totalPrice = totalPrice;
      state.detail.gst = gst;
      state.detail.bookingFee = bookingFee;
      state.detail.promoterCode = promoterCode;
      state.detail.couponCode = couponCode;
    },
  },
});
