import { createSliceForList } from "../helpers";

export const vipTablesSlice = createSliceForList({
  name: "vipTables",
  reducers: {
    vipTablesFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
      state.dataForEdit = undefined;
    },

    vipTableFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload;
      state.error = null;
    },

    // createEvent
    vipTableCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.dataForEdit = undefined;
    },
    // updateEvent
    vipTableUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.dataForEdit = undefined;
    },
    // deleteEvent
    vipTableDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteEvent
    vipTablesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // duplicateEvent
    vipTableDuplicated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    giftVipTableFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    storeGiftVipTableFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
