import * as partnerApi from "./partnerApi";
import { partnerSlice } from "./partnerSlice";
import { callTypes } from "../helpers";

const { actions } = partnerSlice;

export const getPartnerList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return partnerApi
    .getPartnerList(queryParams)
    .then((result) => {
      dispatch(actions.partnersFetched(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getPartnerById = (partnerId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // if (partnerId === undefined) {
  //   return dispatch(actions.partnerFetched(undefined));
  // } else {
  return partnerApi
    .getPartnerById(partnerId)
    .then((data) => {
      dispatch(actions.partnerDetailFetched(data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
  // }
};
export const getPartnerSelect = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return partnerApi
    .getPartnerSelect()
    .then((result) => {
      dispatch(actions.partnerSelectFetched(result));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
