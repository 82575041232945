import React, { useEffect, useMemo, useRef, useState } from "react";
import { Upload, Modal } from "antd";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import { shallowEqual, useSelector } from "react-redux";
import { useField } from "formik";
import styled from "styled-components";
import CustomConfirm from "./CustomConfirm";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const CustomDropzponeWrapper = styled.div`
  .ant-upload.is-invalid {
    border-color: #f64e60;
  }
`;

const getCSSClasses = (touched, error) => {
  const classes = ["customer-dropzone"];
  if (touched && error) {
    classes.push("is-invalid");
  }

  if (touched && !error) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export default function CustomDropzpone({
  initValues,
  maxFiles = 20,
  accept = "image/*",
  height,
  maxFilesize = 1,
  name,
  onChange,
  uploadUrl = "/business/ossupload/ossFileUpload",
  // uploadUrl = "http://47.74.88.130:9081/ossupload/ossFileUpload",
  ...props
}) {
  const { authToken } = useSelector((state) => state.auth, shallowEqual);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [count, setCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [field, meta, helpers] = useField({
    name,
    validate: (value) => {
      // console.log(field.value);
      if (!(field.value && field.value.length > 0)) {
        return "One or more file are required";
      } else {
        return "";
      }
    },
  });
  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${authToken}`,
    };
  }, []);
  useEffect(() => {
    // if (typeof initValues === "string") {
    //   initValues = new List[initValues]();
    // }
    // console.log(initValues);
    if (initValues.length > 0) {
      if (count == 0) {
        const files = initValues.map((url) => {
          if (url.split(".")[4] == "mp4" || url.split(".")[4] == "MP4") {
            return {
              url:
                url +
                "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto",
              name: url.substring(url.lastIndexOf("/") + 1),
            };
          } else {
            return {
              url: url,
            };
          }
        });
        setFileList(files);
        setCount((prev) => prev + 1);
      }
    }
  }, [initValues]);

  const action = useMemo(() => {
    if (!uploadUrl.startsWith("http")) {
      uploadUrl = process.env.REACT_APP_API_URL + uploadUrl;
    }
    return uploadUrl;
  }, []);
  const handleCancel = () => {
    var myVideo = document.getElementById("previewVideo");
    if (myVideo) {
      myVideo.pause();
    }
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // console.log(file);

    setPreviewImage(file.preview || file.url.split("?")[0]);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList, file }) => {
    setFileList((prev) => [...fileList]);
  };
  const beforeUpload = (file) => {
    const moreThan5M = file.size / 1024 / 1024 > 5;
    // return new Promise((resolve, reject) => {
    //   if (moreThan5M) {
    //     // message.error('上传文件不能大于10M')
    //     console.log("123");
    //     return reject(false);
    //   } else {
    //     console.log("321");
    //     return resolve(true);
    //   }
    // });
    if (moreThan5M) {
      // console.log("123");
      setShowError(true);
      return Upload.LIST_IGNORE;
    }
  };

  useEffect(() => {
    if (onChange) {
      const urlList = [];
      fileList.forEach((file) => {
        if (file.url) {
          urlList.push(file.url);
        } else {
          if (file.status === "done")
            urlList.push(file.response.result.data[0]);
        }
      });
      if (urlList.length === fileList.length) {
        onChange(urlList);
      }
    }
  }, [fileList]);

  // render() {
  //   const { previewVisible, previewImage, fileList, previewTitle } = this.state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload{" "}
      </div>
    </div>
  );
  return (
    <>
      <CustomDropzponeWrapper>
        <Upload
          className={getCSSClasses(meta.touched, meta.error)}
          accept={accept}
          key={name}
          headers={headers}
          name="file"
          action={action}
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          maxCount={maxFiles}
          beforeUpload={beforeUpload}
        >
          {uploadButton}
          {/* {fileList.length >= maxFiles ? null : uploadButton} */}
        </Upload>
        {meta.error && meta.touched && (
          <div className="invalid-feedback"> {meta.error} </div>
        )}
      </CustomDropzponeWrapper>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {previewImage.split(".")[4] == "mp4" ||
        previewImage.split(".")[4] == "MP4" ? (
          <video id="previewVideo" height="600" controls>
            <source
              // src="https://hivee.oss-accelerate.aliyuncs.com/hivee/image/202203280706532695607f3126bc4ed4e4a89493ffa226034ab.mp4"
              src={previewImage}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        )}
      </Modal>
      <CustomConfirm
        show={showError}
        title={"Error"}
        message={"The image for uploading cannot be larger than 5M."}
        setShow={setShowError}
      />
    </>
  );
}
