import { createSliceForList } from "../helpers";

export const stripeSlice = createSliceForList({
  name: "stripe",
  reducers: {
    stripeListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.records = records;
      state.total = total;
    },
    stripeTotalFetched: (state, action) => {
      const { result } = action.payload;
      state.result = result;
      state.totalLoading = false;
    },
    stripeDetailFetched: (state, action) => {
      const { result } = action.payload;
      state.detail.result = result;
      state.error = null;
      state.detail.isLoading = false;
    },
    stripeDetailListFetched: (state, action) => {
      const { total, records } = action.payload;
      state.detail.listLoading = false;
      state.error = null;
      state.detail.records = records;
      state.detail.total = total;
    },
  },
});
