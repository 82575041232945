const eventsManagement = {
  path: "/event",
  icon: "/media/svg/icons/General/Star.svg",
  text: "Event Management",
  children: [
    {
      path: "/eventList",
      name: "eventList",
      icon: "/media/svg/icons/General/Star.svg",
      text: "Event Management",
      componentPath: "eventsManagement/EventsPage",
      hide: false,
      children: [
        // level 2
        {
          path: "/new",
          name: "eventNew",
          hide: true,
          componentPath: "eventsManagement/EventEditPage",
        },
        {
          path: "/:eventId/edit",
          name: "eventEdit",
          hide: true,
          componentPath: "eventsManagement/EventEditPage",
        },
        {
          path: "/:eventId/general-tickets",
          name: "generalTicketList",
          hide: true,
          componentPath: "generalTicketsManagement/GeneralTicketsPage",
          children: [
            {
              path: "/:generalTicketId/gift",
              name: "generalTicketGift",
              componentPath: "ticketGift/TicketGiftPage",
            },
          ],
        },
        {
          path: "/:eventId/vip-tables",
          name: "vipTableList",
          hide: true,
          componentPath: "vipTablesManagement/VipTablesPage",
          children: [
            {
              path: "/new",
              name: "vipTableNew",
              componentPath: "vipTablesManagement/VipTableEditPage",
            },
            {
              path: "/:vipTableId/edit",
              name: "vipTableEdit",
              componentPath: "vipTablesManagement/VipTableEditPage",
            },
            {
              path: "/:vipTableId/package-details",
              name: "packageDetailList",
              componentPath: "vipTablesManagement/PackageDetailsPage",
            },
            {
              path: "/:vipTableId/gift",
              name: "vipTableGift",
              componentPath: "tableGift/TableGiftPage",
            },
          ],
        },
      ],
    },
    {
      path: "/packages",
      name: "packageList",
      text: "Package Management",
      componentPath: "packagesManagement/PackagesPage",
    },
  ],
};

const postsManagement = {
  // level 1
  path: "/posts",
  name: "postList",
  icon: "/media/svg/icons/Design/PenAndRuller.svg",
  text: "Post Management",
  componentPath: "postManagement/PostListPage",
  children: [
    // level 2
    {
      path: "/new",
      name: "postNew",
      hide: true,
      componentPath: "postManagement/PostEditPage",
    },
    {
      path: "/:postId/edit",
      name: "postEdit",
      hide: true,
      componentPath: "postManagement/PostEditPage",
    },
  ],
};

const djManagement = {
  path: "/dj",
  icon: "/media/svg/icons/Media/Equalizer.svg",
  text: "DJ Management",
  name: "dj",
  children: [
    {
      path: "/djs",
      name: "djList",
      text: "DJ Management",
      componentPath: "djsManagement/DJsPage",
      children: [
        {
          path: "/new",
          name: "djNew",
          hide: true,
          componentPath: "djsManagement/DJEditPage",
        },
        {
          path: "/:djId/edit",
          name: "djEdit",
          hide: true,
          componentPath: "djsManagement/DJEditPage",
        },
      ],
    },
    {
      path: "/musics",
      name: "musicList",
      text: "Music",
      componentPath: "musicsManagement/MusicsPage",
      children: [
        {
          path: "/new",
          name: "musicNew",
          hide: true,
          componentPath: "musicsManagement/MusicEditPage",
        },
        {
          path: "/:musicId/edit",
          name: "musicEdit",
          hide: true,
          componentPath: "musicsManagement/MusicEditPage",
        },
      ],
    },
    {
      path: "/musicStyles",
      name: "musicStyleList",
      text: "Music Style",
      componentPath: "musicStylesManagement/MusicStylesPage",
      children: [
        {
          path: "/new",
          name: "musicStyleNew",
          hide: true,
          componentPath: "musicStylesManagement/MusicStyleEditPage",
        },
        {
          path: "/:musicStyleId/edit",
          name: "musicStyleEdit",
          hide: true,
          componentPath: "musicStylesManagement/MusicStyleEditPage",
        },
      ],
    },
  ],
};

const settingsManagement = {
  path: "/settings",
  icon: "/media/svg/icons/General/Settings-2.svg",
  text: "General Settings",
  name: "settings",
  children: [
    {
      path: "/popular",
      name: "popular",
      text: "Popular Search",
      componentPath: "popularSearches/PopularPage",
    },
    {
      path: "/static",
      name: "static",
      text: "Static Page Management",
      componentPath: "staticPageManagement/StaticPage",
    },
    {
      path: "/onboardingAd",
      name: "onboardingAd",
      text: "Onboarding ad.",
      componentPath: "onboardingAd/OnboardingAdPage",
    },
  ],
};

const featuredsManagement = {
  path: "/featured",
  icon: "/media/svg/icons/General/Fire.svg",
  text: "Featured Management",
  name: "feature",
  children: [
    // level 1
    {
      path: "/featuredEvents",
      name: "featuredEventList",
      text: "Featured Event",
      componentPath: "featuredEventsManagement/FeaturedEventsPage",
      children: [
        // level 2
        {
          path: "/new",
          name: "featuredEventNew",
          hide: true,
          componentPath: "featuredEventsManagement/FeaturedEventNewPage",
        },
      ],
    },
    {
      path: "/featuredPosts",
      name: "featuredPostList",
      text: "Featured Post",
      componentPath: "featuredPostsManagement/FeaturedPostsPage",
      children: [
        // level 2
        {
          path: "/new",
          name: "featuredPostNew",
          hide: true,
          componentPath: "featuredPostsManagement/FeaturedPostNewPage",
        },
      ],
    },
    {
      path: "/featuredDjs",
      name: "featuredDjList",
      text: "Featured DJ",
      componentPath: "featuredDjsManagement/FeaturedDjsPage",
      children: [
        // level 2
        {
          path: "/new",
          name: "featuredDjNew",
          hide: true,
          componentPath: "featuredDjsManagement/FeaturedDjNewPage",
        },
      ],
    },
    {
      path: "/featuredMusics",
      name: "featuredMusicList",
      text: "Featured Music",
      componentPath: "featuredMusicsManagement/FeaturedMusicsPage",
      children: [
        // level 2
        {
          path: "/new",
          name: "featuredMusicNew",
          hide: true,
          componentPath: "featuredMusicsManagement/FeaturedMusicNewPage",
        },
      ],
    },
  ],
};

const adsManagement = {
  // level 1
  path: "/ads",
  name: "adList",
  icon: "/media/svg/icons/General/Fire.svg",
  text: "Ad. Management",
  componentPath: "adsManagement/AdsPage",
  children: [
    // level 2
    {
      path: "/new",
      name: "adNew",
      hide: true,
      componentPath: "adsManagement/AdEditPage",
    },
    {
      path: "/:adId/edit",
      name: "adEdit",
      hide: true,
      componentPath: "adsManagement/AdEditPage",
    },
  ],
};

const usersManagement = {
  icon: "/media/svg/icons/Files/User-folder.svg",
  text: "User Management",
  name: "user",
  path: "/user",
  children: [
    {
      path: "/users",
      name: "userList",
      text: "User Management",
      componentPath: "usersManagement/UsersPage",
      children: [
        {
          path: "/userDetail/:userId",
          name: "userDetail",
          text: "User Detail",
          hide: true,
          componentPath: "userDetail/UserDetailPage",
        },
      ],
    },
    {
      path: "/deleteAccount",
      name: "deleteAccount",
      text: "Delete Account Requests",
      componentPath: "deleteAccount/DeleteAccountPage",
    },
  ],
};

const groupsManagement = {
  // level 1
  path: "/groups",
  name: "groupList",
  icon: "/media/svg/icons/Communication/Group.svg",
  text: "Group Management",
  componentPath: "groupManagement/GroupListPage",
  children: [
    {
      path: "/:eventId/groupDetail",
      name: "eventGroup",
      hide: true,
      // componentPath: "vipTablesManagement/GroupManagementPage",
      componentPath: "eventGroup/EventGroupPage",
      children: [
        {
          path: "/:groupId/assignStaff",
          name: "assignStaff",
          text: "Assign Staff",
          hide: true,
          componentPath: "assignStaff/AssignStaffPage",
        },
      ],
    },
  ],
};

const ordersManagement = {
  // level 1
  path: "/order",
  name: "order",
  icon: "/media/svg/icons/General/Clipboard.svg",
  text: "Order Management",
  // componentPath: 'orderManagement/OrderListPage',
  children: [
    // level 2
    {
      path: "/orderDetail/:orderNo",
      name: "orderDetail",
      text: "Order Detail",
      hide: true,
      componentPath: "orderDetail/OrderDetailPage",
    },
    {
      path: "/orders",
      name: "orderList",
      text: "Order Management",
      componentPath: "orderManagement/OrderListPage",
    },
    {
      path: "/ticket",
      name: "ticketManagement",
      text: "Ticket Management",
      componentPath: "ticketManagement/TicketPage",
    },
    {
      path: "/salesRecord",
      name: "salesRecord",
      text: "Sales Record",
      componentPath: "salesRecord/SalesRecordPage",
    },
    {
      path: "/freeTicketOrders",
      name: "freeTicketOrders",
      text: "Free Ticket Orders",
      componentPath: "freeTicketOrders/FreeTicketOrdersPage",
    },
  ],
};

const promotorManagement = {
  // level 1
  path: "/promotor",
  icon: "/media/svg/icons/General/Fire.svg",
  text: "Promotor Management",
  children: [
    {
      path: "/promotorList",
      name: "promotorManagemnet",
      icon: "/media/svg/icons/General/Fire.svg",
      text: "Promotor Management",
      componentPath: "promotorManagement/PromotorPage.js",
      children: [
        // level 2
        {
          path: "/promotorDetail/:userId",
          name: "promotorDetail",
          hide: true,
          componentPath: "promotorDetail/PromotorDetailPage",
        },
        {
          path: "/promotorDetailPost/:userId",
          name: "promotorDetailPost",
          hide: true,
          componentPath: "promotorDetail/PromotorDetailPostPage",
        },
        {
          path: "/addPromotor",
          name: "addPromotor",
          hide: true,
          componentPath: "addPromotor/AddPromotorPage",
        },
        {
          path: "/promotorEvent/:eventId/:userId",
          name: "promotorEvent",
          hide: true,
          componentPath: "promotorEvent/PromotorEventPage",
        },
      ],
    },
  ],
};

const messageCenterManagement = {
  // level 1
  path: "/messageCenter",
  name: "messageCenter",
  icon: "/media/svg/icons/General/Clipboard.svg",
  text: "Message Center",
  // componentPath: 'orderManagement/OrderListPage',
  children: [
    // level 2
    {
      path: "/feedback",
      name: "feedback",
      text: "Recevied Feedback",
      componentPath: "feedbackManagement/FeedbackPage",
    },
    {
      path: "/issue",
      name: "issue",
      text: "Recevied Issue",
      componentPath: "issueManagement/IssuePage",
    },
  ],
};

const adminManagement = {
  // level 1
  path: "/admin",
  name: "adminManagement",
  icon: "/media/svg/icons/Files/User-folder.svg",
  text: "Admin Management",
  // componentPath: 'orderManagement/OrderListPage',
  children: [
    // level 2
    {
      path: "/role",
      name: "role",
      text: "Role Management",
      componentPath: "roleManagement/RolePage",
    },
    {
      path: "/systemUser",
      name: "systemUser",
      text: "System User Management",
      componentPath: "systemUserManagement/SystemUserPage",
    },
    {
      path: "/version",
      name: "version",
      text: "App version control",
      componentPath: "version/VersionPage",
    },
    {
      path: "/menu",
      name: "menu",
      text: "Menu Management",
      componentPath: "menuManagement/MenuPage",
      children: [
        {
          path: "/:menuId/edit",
          name: "menuEdit",
          text: "Menu Edit",
          hide: true,
          componentPath: "menuManagement/MenuEditPage",
        },
        {
          path: "/new",
          name: "menuNew",
          hide: true,
          componentPath: "menuManagement/MenuEditPage",
        },
      ],
    },
  ],
};

const transactionManagement = {
  // level 1
  path: "/transaction",
  name: "transaction",
  icon: "/media/svg/icons/Shopping/Wallet.svg",
  text: "Transaction Management",
  componentPath: "",
  children: [
    // level 2
    {
      text: "Stripe",
      path: "/stripe",
      name: "stripe",
      componentPath: "stripe/StripePage",
      children: [
        {
          path: "/:transactionId/:storeId/detail",
          name: "stripeDetail",
          hide: true,
          componentPath: "stripeDetail/StripeDetailPage",
        },
      ],
    },
  ],
};
const partnerManagement = {
  // level 1
  path: "/partner",
  name: "partnerManagement",
  icon: "/media/svg/icons/Shopping/Wallet.svg",
  text: "Partner Management",
  componentPath: "",
  children: [
    // level 2
    {
      text: "Partner",
      path: "/partnerList",
      name: "partner",
      componentPath: "partnerManagement/PartnerPage",
      children: [
        {
          path: "/:partnerId/detail",
          name: "partnerDetail",
          hide: true,
          componentPath: "partnerManagement/PartnerDetailPage",
        },
      ],
    },
  ],
};
export default [
  eventsManagement,
  postsManagement,
  djManagement,
  adsManagement,
  settingsManagement,
  featuredsManagement,
  usersManagement,
  groupsManagement,
  ordersManagement,
  promotorManagement,
  messageCenterManagement,
  adminManagement,
  transactionManagement,
  partnerManagement,
];
