import { createSliceForList } from "../helpers";

export const tablePackagesSlice = createSliceForList({
  name: "tablePackages",
  reducers: {
    tablePackagesFetched: (state, action) => {
      const { records } = action.payload;
      console.log(records);
      state.listLoading = false;
      state.error = null;
      state.records = records;
    },
  },
});
