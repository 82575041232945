export function flattenRoutes(routes) {
    function* walk(children, parent) {
        for (let route of children) {
            const _route = {
                ...route,
                path: parent.path + route.path
            }
            yield _route
            let _children = route.children
            if (_children)
                yield* walk(_children, _route)
        }
    }

    function* inner(routes) {
        for (let route of routes) {
            yield route
            let children = route.children
            if (children) {
                yield* walk(route.children, route)
            }
        }
    }

    return Array.from(inner(routes))
}

export function assemblePath(...path) {
    return path.reduce((i, j) => {
        return i + j
    })
}
